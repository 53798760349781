import React, { ReactComponentElement, ReactElement } from "react";
import { Redirect, Route, RouteProps, useHistory } from "react-router-dom";

interface IProtectedRouteProps extends RouteProps {
  component: React.FC;
}
const ProtectedRoute: React.FC<IProtectedRouteProps> = ({
  component: Component,
  ...restOfProps
}) => {
  const token = localStorage.getItem("ddm-external-token");
  console.log("Token ", token);
  const history = useHistory();
  if (!token) {
    history.push("/login");
  }

  return <Route {...restOfProps} render={() => <Component />} />;
};

export default ProtectedRoute;
