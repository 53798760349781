import React from "react";
import { Router, Route } from "react-router-dom";
import "./App.scss";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Login } from "./login/Login";
import { Home } from "./home/Home";
import { ddmTheme } from "@ddm-design-system/tokens";
import ProtectedRoute from "./components/ProtectedRoute";
import { ThemeProvider } from "styled-components";
import { createBrowserHistory } from "history";
const RECAPTCHA_KEY = "6LdKrtUUAAAAAGi1c1WAq0_7r7n4tBpRv5WtSNo6";

const history = createBrowserHistory();
const App = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY} language="en">
      <ThemeProvider theme={ddmTheme}>
        <div className="App">
          <Router history={history}>
            <Route exact path="/login" component={Login} />
            <ProtectedRoute exact path="/" component={Home} />
          </Router>
        </div>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  );
};

export default App;
