import { ErrorTextInput } from "@ddm-design-system/textinput";
import { PrimaryButton } from "@ddm-design-system/button";
import { BodyHighlight, SectionTitle } from "@ddm-design-system/typography";
import React, { useEffect, useState } from "react";
import { ProgressBar } from "@ddm-design-system/progress-bar";
import { api } from "../constants";
import "./home.scss";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

const parseLabel = (obj: any) => {
  if (obj.jobProcessDetails.numberOfCanceledThings > 0) {
    return "Canceled";
  } else if (obj.jobProcessDetails.numberOfFailedThings > 0) {
    return "Failed";
  } else if (obj.jobProcessDetails.numberOfInProgressThings > 0) {
    return "In Progress";
  } else if (obj.jobProcessDetails.numberOfQueuedThings > 0) {
    return "Queued";
  } else if (obj.jobProcessDetails.numberOfRejectedThings > 0) {
    return "Rejected";
  } else if (obj.jobProcessDetails.numberOfRemovedThings > 0) {
    return "Removed";
  } else if (obj.jobProcessDetails.numberOfSucceededThings > 0) {
    return "Success";
  } else if (obj.jobProcessDetails.numberOfTimedOutThings > 0) {
    return "Timed Out";
  } else if (obj.jobProcessDetails.numberOfRemovedThings > 0) {
    return "Removed";
  }
  return "";
};
const progressMap: { [key: string]: number } = {
  Starting: 0,
  Queued: 15,
  "In Progress": 50,
  Success: 100,
  Rejected: 100,
  Failed: 100,
  Canceled: 100,
  "Timed Out": 100,
};

interface IJobProps {
  jobFilename: string;
  jobId: string;
  jobLabel?: string;
  jobDate?: Date;
  jobSerial: string;
}
const Job: React.FC<IJobProps> = ({ jobId, jobLabel, jobSerial, jobDate }) => {
  const [fromNow, setFromNow] = useState();

  useEffect(() => {
    const int = setInterval(() => {
      setFromNow(jobDate ? (dayjs(jobDate) as any).fromNow() : "");
    }, 5000);

    return () => clearInterval(int);
  }, [jobDate]);
  return (
    <div className="job-item">
      <div className="job-item-id">{jobId}</div>
      <div className="job-item-serial">{jobSerial}</div>
      <div className="job-start-date">{fromNow}</div>
      <div className="job-item-progress">
        <div className="job-item-label">{jobLabel}</div>
        <ProgressBar value={jobLabel ? progressMap[jobLabel] || 0 : 0} />
      </div>
    </div>
  );
};

let interval: any;
export const Home = () => {
  const [currentJobs, setCurrentJobs] = useState<
    {
      filename: string;
      id: string;
      serial: string;
      date: Date;
      label: string;
    }[]
  >([]);
  const [perform, setPerform] = useState<string>("Perform OTA");
  const [serial, setSerial] = useState<string>("");
  const [status, setStatus] = useState<string | null>(null);

  const [file, setFile] = useState<any>();
  const auth = localStorage.getItem("ddm-external-token") || "";

  useEffect(() => {
    if (file) {
      uploadWithJSON(file);
    }
  }, [file]);

  const performOta = async (serial: string) => {
    setPerform("Uploading...");
    setStatus(null);
    // /ota/${{serial}} octect-stream FILE Authorization: Bearer ${token}

    console.log(file);
    const fileOctect: any = await uploadWithJSON(file);
    try {
      const res = await fetch(`${api}/ota/${serial}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/octet-stream",
          Authorization: auth.trim(),
        },
        method: "POST",
        body: fileOctect,
      });
      const resJson = await res.json();
      setPerform("Perform OTA");
      setStatus("OTA successfully initiated - ID:" + resJson);

      setCurrentJobs((j) => {
        const newJ = j.concat([
          {
            filename: file.name,
            id: resJson,
            serial,
            date: new Date(),
            label: "Starting",
          },
        ]);
        newJ.sort((a, b) =>
          new Date(a.date).getTime() > new Date(b.date).getTime() ? -1 : 1
        );
        localStorage.setItem("ddm-external-jobs", JSON.stringify(newJ));
        clearInterval(interval);
        return newJ;
      });
    } catch (err) {
      setPerform("Perform OTA");
      setStatus("Error performing OTA ");
      console.log("ERROR", err);
    }

    // console.log(localStorage.getItem("ddm-external-ota"));
  };

  const uploadWithJSON = async (file: any) => {
    const toBase64 = (file: any) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    const data = {
      file: await toBase64(file),
    };
    return data.file;
  };

  const checkOta = async (jobId: string) => {
    // /status/${{id}}
    try {
      const res = await fetch(`${api}/status/${jobId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: auth.trim(),
        },
        method: "GET",
      });
      const resJson = await res.json();
      return resJson;
    } catch (err) {
      console.log(err);
    }
    return null;
  };

  useEffect(() => {
    const jobString = localStorage.getItem("ddm-external-jobs");
    let jobs = [];
    if (jobString) {
      jobs = JSON.parse(jobString);
    }

    setCurrentJobs(jobs.slice(0, 5) || []);
  }, []);

  useEffect(() => {
    clearInterval(interval);
    interval = setInterval(async () => {
      const copyJobs = currentJobs.slice();
      for (let i = 0; i < copyJobs.length; i++) {
        const obj = await checkOta(copyJobs[i].id);
        if (obj) {
          copyJobs[i].label = parseLabel(obj);
        }
      }
      copyJobs.sort((a, b) =>
        new Date(a.date).getTime() > new Date(b.date).getTime() ? -1 : 1
      );
      setCurrentJobs(copyJobs);

      localStorage.setItem("ddm-external-jobs", JSON.stringify(copyJobs));

      return () => clearInterval(interval);
    }, 10000);
  }, [currentJobs]);
  return (
    <div className="home-page">
      <div className="ota">
        <input
          onChange={(e) => {
            if (e.target.files && e.target.files.length > 0) {
              setFile(e.target.files[0]);
            }
          }}
          type="file"
          className="file-upload"
        />
        <ErrorTextInput
          className="thingid-input"
          label="Serial"
          value={serial}
          onChange={(e) => setSerial(e.target.value)}
        />
        {status && <BodyHighlight>{status}</BodyHighlight>}
        <PrimaryButton
          disabled={!file || !serial}
          onClick={() => performOta(serial)}
        >
          {perform}
        </PrimaryButton>
      </div>

      <div className="status">
        {currentJobs.length === 0 ? (
          <SectionTitle>No Current Jobs</SectionTitle>
        ) : (
          <table width="100%">
            <thead>
              <tr>
                <th>Firmware</th>
                <th>Serial</th>
                <th>Start Date</th>
                <th>Progress</th>
              </tr>
            </thead>
            <tbody>
              {currentJobs.map((job, index) => (
                <tr key={index}>
                  <td>{job.filename}</td>
                  <td>{job.serial}</td>
                  <td>{job.date ? (dayjs(job.date) as any).fromNow() : ""}</td>
                  <td>
                    <div className="job-item-progress">
                      <div className="job-item-label">{job.label}</div>
                      <ProgressBar
                        value={job.label ? progressMap[job.label] || 0 : 0}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
