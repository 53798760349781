import React, { useState } from "react";
import { ErrorTextInput } from "@ddm-design-system/textinput";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Icon, SplashLoading } from "@ddm-design-system/icon";
import { PrimaryButton } from "@ddm-design-system/button";
import { api } from "../constants";
import "./login.scss";
import { Redirect, useHistory } from "react-router";

const renderLogo = () => (
  <div className="logo-container">
    <Icon name="dm-full-logo" height={32} fill={"var(--color-white)"} />
  </div>
);

export const Login = () => {
  const [recaptchaToken, setRecaptchaToken] = useState(
    localStorage.getItem("grecaptcha") || ""
  );
  const { executeRecaptcha = () => "" } = useGoogleReCaptcha();

  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [error, setError] = useState<string>();
  const [redirect, setRedirect] = useState(false);
  const history = useHistory();

  // /login { username, password } queryParam - recaptcha

  const login = async () => {
    const recaptchaToken = await executeRecaptcha();

    console.log({ username, password, recaptcha: recaptchaToken });
    try {
      const res = await fetch(`${api}/login`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          username,
          password,
          recaptcha: recaptchaToken,
        }),
      });
      console.log("RES ", res);
      const resJson = await res.json();
      console.log(resJson);
      localStorage.setItem("ddm-external-token", resJson);
      setRedirect(true);
    } catch (err) {
      console.log(err);
      setError("Wrong login credentials");
    }
  };

  console.log(redirect);

  return redirect ? (
    <Redirect to="/" />
  ) : (
    <div className="login-page">
      <div className="login-container">
        {renderLogo()}
        <ErrorTextInput
          className="textinput"
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <ErrorTextInput
          className="textinput"
          label="Password"
          type="password"
          value={password}
          error={error}
          onChange={(e) => setPassword(e.target.value)}
        />
        {/* <GoogleReCaptcha
          onVerify={(reToken: string) => {
            setRecaptchaToken(reToken);
            localStorage.setItem("grecaptcha", reToken);
          }}
        /> */}
        <PrimaryButton className="login" onClick={login}>
          LOGIN
        </PrimaryButton>
      </div>
    </div>
  );
};
